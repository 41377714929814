import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { LeadService } from 'src/app/services/lead.service';
import { IBlockNewsletter } from 'src/models/zeus';
import { RdstationService } from 'src/app/services/rdstation.service';
import { environment } from 'src/environments/environment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-block-news-letter',
  templateUrl: './block-news-letter.component.html',
  styleUrls: ['./block-news-letter.component.scss']
})
export class BlockNewsLetterComponent implements OnInit {

  @Input() identifier = 'newsletter/' + this.location.path(false).split('?')[0];
  @Input() block!: IBlockNewsletter;
  public envioStatus?: 'carregando' | 'enviado' | 'enviado-newsletter' | 'erro' | undefined;

  constructor(
    public dataLayerService: DataLayerService,
    private location: Location,
    private leadService: LeadService
  ) { }

  public form = new UntypedFormGroup({
    nome: new UntypedFormControl('', Validators.compose([Validators.required])),
    email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email])),
    interessecaminhao: new UntypedFormControl(''),
    interessecavalomecanico: new UntypedFormControl(''),
    interesseimplementos: new UntypedFormControl(''),
    interesseonibus: new UntypedFormControl(''),
    interesselinhaverde: new UntypedFormControl(''),
    interesselinhaamarela: new UntypedFormControl(''),
  });

 
  public ngOnInit(): void {
    this.dataLayerService.formFields(this.identifier, this.form);
  }
 

  public onSubmit() {

    const f = this.form.value;

    const pathname  = window.location.pathname
    if (pathname.startsWith("/")) {
      const newUrl = pathname.substr(1);
      const hash      = window.location.hash
      this.identifier = `${newUrl}${hash}`
    }

    let dados = {
      'name': f.nome,
      'email': f.email,
      'interessecaminhao': f.interessecaminhao,
      'interessecavalomecanico': f.interessecavalomecanico,
      'interesseimplementos': f.interesseimplementos,
      'interesseonibus': f.interesseonibus,
      'interesselinhaverde': f.interesselinhaverde,
      'interesselinhaamarela': f.interesselinhaamarela,
      'identifier': this.identifier,
      'token_rdstation': environment.RD_PUBLIC_TOKEN,
    };

    if (this.form.valid) {
      this.envioStatus = 'carregando';
      this.leadService.enviar(dados, [], true).subscribe(
        (sucesso) => {
          this.envioStatus = sucesso? 'enviado-newsletter' : 'erro'

          if (this.envioStatus == 'enviado-newsletter') {
            this.dataLayerService.formSucesso(this.identifier)
          }
        })
    }
  }
}