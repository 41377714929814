import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, take } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { PDVParams } from "src/models/PDVParams";
import { FiltrosPDV } from "src/models/FiltrosPDV";
import { PDVData, VehicleQueryResult } from "src/models/PDVData";
import { ProdutoPdvComMediaeFipe } from "src/models/ProdutoPdv";

export type CountVeiculoPorCategoriaResponse = {
  countTodos: number;
  countCaminhao: number;
  countImplemento: number;
  countMaquinasAgricolas: number;
  countOnibus: number;
};

export type SearchByCityStateResponse = {
  cidades: { cidade: string; filiais: string[]; total: number }[];
  estados: { estado: string; filiais: string[]; total: number }[];
};

@Injectable({
  providedIn: "root",
})
export class pdvService {
  private _filtrosVeiculosSubject = new BehaviorSubject<FiltrosPDV>({
    marcas: [],
    filiais: [],
    eixos: [],
    placas: [],
    cores: [],
    implementos: [],
    ultimaAtualizacao: new Date(),
  });
  public readonly filtroVeiculos$: Observable<FiltrosPDV> =
    this._filtrosVeiculosSubject.asObservable();

  constructor(private _http: HttpClient) {}

  public buscarVeiculos(parametros: PDVParams): Observable<PDVData> {
    let params = new HttpParams().set(
      "categoria",
      parametros.categoria.toLowerCase()
    );

    params = params.append(
      "modeloMarca",
      JSON.stringify(parametros.modeloMarca)
    );
    params = params.append("modelo", parametros.modelo);
    if (parametros.destaque)
      params = params.append("destaque", parametros.destaque);
    params = params.append("order", parametros.ordem);
    params = params.append("marca", parametros.marcas);
    params = params.append("eixo", parametros.eixo);
    params = params.append("filiais", JSON.stringify(parametros.filiais));
    params = params.append("placa", parametros.placa);
    params = params.append("cor", parametros.cor);
    params = params.append("anoInicio", parametros.anoInicio);
    params = params.append("anoFim", parametros.anoFim);
    params = params.append("kmInicio", parametros.kmInicio);
    params = params.append("kmFim", parametros.kmFim);
    params = params.append("precoInicio", parametros.precoInicio);
    params = params.append("precoFim", parametros.precoFim);
    params = params.append(
      "implementos",
      JSON.stringify(parametros.implementos)
    );
    params = params.append("pagina", parametros.pagina);

    const result = this._http.get<PDVData>(
      environment.API_URL + "/api/veiculosdb/buscar-veiculos",
      {
        params: params,
      }
    );
    return result;
  }

  public listarFiltros(categoria: string | null): void {
    let params = new HttpParams().set("categoria", categoria!);

    this._http
      .get<FiltrosPDV>(environment.API_URL + "/api/veiculosdb/listar-filtros", {
        params: params,
      })
      .pipe(take(1))
      .subscribe((resultado) => {
        this._filtrosVeiculosSubject.next(resultado);
      });
  }

  public buscarVeiculo(id: string): Observable<ProdutoPdvComMediaeFipe> {
    return this._http.get<ProdutoPdvComMediaeFipe>(
      environment.API_URL + "/api/veiculosdb/buscar-veiculo-vamos-fipe/" + id
    );
  }

  public countVeiculosPorCategoria(): Observable<CountVeiculoPorCategoriaResponse> {
    return this._http.get<CountVeiculoPorCategoriaResponse>(
      environment.API_URL + "/api/veiculosdb/count-categorias"
    );
  }

  public buscarAutocomplete({
    termo,
    categoria,
  }: {
    termo: string;
    categoria: string;
  }): Observable<VehicleQueryResult> {
    return this._http.get<VehicleQueryResult>(
      environment.API_URL +
        "/api/veiculosdb/veiculo-por-modelo-marca?termo=" +
        encodeURIComponent(termo) +
        "&categoria=" +
        encodeURIComponent(categoria)
    );
  }

  public buscarModelosPorMarcaECategoria({
    marca = "",
    categoria = "",
  }): Observable<string[]> {
    return this._http.get<string[]>(
      environment.API_URL + "/api/veiculosdb/modelos-por-marca",
      {
        params: {
          marca,
          categoria,
        },
      }
    );
  }

  public buscarVeiculoPorCidadeEstado({
    termo = "",
  }): Observable<SearchByCityStateResponse> {
    return this._http.get<SearchByCityStateResponse>(
      environment.API_URL + "/api/veiculosdb/veiculo-por-cidade-estado",
      {
        params: {
          termo,
        },
      }
    );
  }
}
