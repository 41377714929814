import { Component, HostListener, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, Observable, Subscription } from "rxjs";
import { InputMasks } from "src/app/helpers/mask/InputMask";
import { GlobalStateService } from "src/app/services/global-state.service";
import { MenuService } from "src/app/services/menu.service";
import {
  pdvService,
  SearchByCityStateResponse,
} from "src/app/services/pdv.service";
import { Filial } from "src/models/Filial";
import { FiltrosPDV } from "src/models/FiltrosPDV";
import { PDVParams } from "src/models/PDVParams";
import { ProdutoPdv } from "src/models/ProdutoPdv";

@Component({
  selector: "app-block-filtro-destaque",
  templateUrl: "./block-filter-destaque.component.html",
  styleUrls: ["./block-filter-destaque.component.scss"],
})
export class BlockFiltroDestaqueComponent implements OnInit {
  public readonly filtroVeiculos$: Observable<FiltrosPDV> =
    this.pdvService.filtroVeiculos$;
  public checkboxSubscription!: Subscription;
  public filtroMarcasSubscription!: Subscription;
  public pdvSubscription!: Subscription;
  public isMobileLayout = false;
  public veiculos: ProdutoPdv[] = [];
  public paginacao = { paginaAtual: 1, itensTotais: 12 };

  modelosPorMarca: Record<string, string[]> = {};
  filteredModelosPorMarca: Record<string, string[]> = {};
  filteredImplementosControls: AbstractControl<any, any>[] = [];
  filteredImplementos: string[] = [];
  currentMarca = "";
  currentModelo = { marca: "", modelo: "" };
  isModelosOpen = false;
  isImplementosOpen = false;
  isMarcasOpen = false;
  localizacao = "";
  searchCidadeEstado: SearchByCityStateResponse = { cidades: [], estados: [] };
  currentCidadeEstado = "";
  isSearchCidadeEstadoOpen = false;
  searchCidadeEstadoSubscription$ = new Subscription();

  public parametros: PDVParams = {
    modelo: "",
    modeloMarca: [],
    categoria: "",
    ordem: "ano",
    marcas: "",
    eixo: "",
    filiais: [],
    placa: "",
    cor: "",
    anoInicio: "",
    anoFim: "",
    kmInicio: "",
    kmFim: "",
    precoInicio: "",
    precoFim: "",
    implementos: [],
    destaque: true,
    pagina: "1",
  };
  public debounceBuscarModelo: any = null;
  public temPreco = false;
  public temAno = false;
  public temKm = false;
  public filtrosLimpos = false;

  public marcasBusca: string[] = [];

  public modelosBusca: { modelo: string; marca: string }[] = [];

  public placasBusca: string[] = [];

  public coresBusca: string[] = [];

  public implementosBusca: string[] = [];

  public listaOrdem = [
    { id: "ano", label: "Mais Novo" },
    { id: "menorvalor", label: "Menor Valor" },
    { id: "maiorvalor", label: "Maior Valor" },
    { id: "km", label: "Menor Quilometragem" },
  ];

  public categoriaSelecionada = "";

  public marcaSelecionada = "";

  public placaSelecionada = "";

  public corSelecionada = "";

  public lojaSelecionada!: Filial[];

  public marcasNaOrdem: string[] = [];

  public placasNaOrdem: string[] = [];

  public coresNaOrdem: string[] = [];

  public implementos: string[] = [];

  public filtroVeiculosForm: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private pdvService: pdvService,
    private router: Router,
    public menuService: MenuService,
    public globalStateService: GlobalStateService
  ) {
    this.filtroVeiculosForm = this.fb.group({
      modelo: new FormControl(null),
      todasMarcas: new FormControl({ value: true, disabled: false }),
      marcas: new FormArray([]),
      filial: new FormControl(null),
      todasPlacas: new FormControl({ value: true, disabled: false }),
      placas: new FormArray([]),
      todasCores: new FormControl({ value: true, disabled: false }),
      todosImplementos: new FormControl({ value: true, disabled: false }),
      implementos: new FormArray([]),
      cores: new FormArray([]),
      eixo: new FormControl(null),
      ordem: new FormControl({ id: "ano", label: "Mais Novo" }),
      precoInicio: new FormControl(null),
      precoFim: new FormControl(null),
      anoInicio: new FormControl(null, Validators.minLength(4)),
      anoFim: new FormControl(null, Validators.minLength(4)),
      kmInicio: new FormControl(null),
      kmFim: new FormControl(null),
      localizacao: new FormControl(""),
      superPreco: new FormControl(true),
    });

    if (window.screen.width <= 991) {
      this.isMobileLayout = true;
      this.globalStateService.changeToMobile(true);
    }

    this.searchCidadeEstadoSubscription$ = this.filtroVeiculosForm.controls[
      "localizacao"
    ].valueChanges
      .pipe(debounceTime(1000))
      .subscribe(() => {
        this.handleSearchFiliais();
      });
  }

  @HostListener("window:resize") onResize() {
    if (window.screen.width <= 991) {
      this.isMobileLayout = true;
      this.globalStateService.changeToMobile(true);
    } else {
      this.isMobileLayout = false;
      this.globalStateService.changeToMobile(false);
    }
  }

  public ngOnInit(): void {
    this.categoriaSelecionada = "estoque-completo";

    this.parametros.categoria = this.categoriaSelecionada;

    this.checkboxSubscription = this.filtroVeiculos$.subscribe((filtros) => {
      this.limparMarcasFormArray();
      this.limparPlacasFormArray();
      this.limparCoresFormArray();
      this.limparImplementosFormArray();
      this.marcasNaOrdem = this.ordenarPorMarcasDestaque(filtros.marcas);
      this.implementos = filtros.implementos;
      this.filteredImplementos = this.implementos;
      this.placasNaOrdem =
        filtros.placas?.filter((placa) => placa != "indefinido")?.sort() ?? [];
      this.coresNaOrdem =
        filtros.cores?.filter((cor) => cor != "indefinido")?.sort() ?? [];

      filtros.marcas?.forEach(() =>
        this.marcasFormArray.push(new FormControl(false))
      );

      filtros.implementos?.forEach(() =>
        this.implementosFormArray.push(new FormControl(false))
      );

      this.filteredImplementosControls = this.implementosFormArray.controls;
      filtros.placas
        ?.filter((placa) => placa != "indefinido")
        ?.forEach(() => this.placasFormArray.push(new FormControl(false)));
      filtros.cores
        ?.filter((cor) => cor != "indefinido")
        ?.forEach(() => this.coresFormArray.push(new FormControl(false)));
    });

    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        this.veiculos = ativosPDV.lista.results;
        this.paginacao = {
          paginaAtual: ativosPDV.lista.pagination.page,
          itensTotais: ativosPDV.lista.pagination.total,
        };
      });

    this.pdvService.listarFiltros(this.categoriaSelecionada);

    this.form["ordem"].patchValue("ano");
  }

  public ngOnDestroy(): void {
    this.pdvSubscription?.unsubscribe();
    this.checkboxSubscription?.unsubscribe();
    this.filtroMarcasSubscription?.unsubscribe();
    this.searchCidadeEstadoSubscription$.unsubscribe();
  }

  public get form(): {
    [key: string]: AbstractControl<any, any>;
  } {
    return this.filtroVeiculosForm.controls;
  }

  public get marcasFormArray(): FormArray<any> {
    return this.filtroVeiculosForm.controls["marcas"] as FormArray;
  }

  public get placasFormArray(): FormArray<any> {
    return this.filtroVeiculosForm.controls["placas"] as FormArray;
  }

  public get coresFormArray(): FormArray<any> {
    return this.filtroVeiculosForm.controls["cores"] as FormArray;
  }

  public get implementosFormArray(): FormArray<any> {
    return this.filtroVeiculosForm.controls["implementos"] as FormArray;
  }

  public limparFiltros(): void {
    this.temPreco = false;
    this.temAno = false;
    this.temKm = false;
    this.form["kmInicio"].enable();
    this.form["kmFim"].enable();
    this.form["anoInicio"].enable();
    this.form["anoFim"].enable();
    this.form["precoInicio"].enable();
    this.form["precoFim"].enable();

    this.definirValoresPadrao();

    this.parametros = {
      modelo: "",
      modeloMarca: [],
      categoria: this.categoriaSelecionada,
      ordem: "ano",
      marcas: this.marcaSelecionada,
      eixo: "",
      filiais: [],
      placa: this.placaSelecionada,
      cor: this.corSelecionada,
      anoInicio: "",
      anoFim: "",
      kmInicio: "",
      kmFim: "",
      precoInicio: "",
      precoFim: "",
      implementos: [],
      destaque: true,
      pagina: "1",
    };

    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public toggleMenuFiltroMobile(): void {
    this.menuService.toggleMenuFiltroMobile();
  }

  public definirValoresPadrao(): void {
    this.form["ordem"].patchValue("ano");
    this.form["modelo"].patchValue("");
    this.form["filial"].patchValue(null);
    this.form["eixo"].patchValue("");
    this.form["precoInicio"].patchValue("");
    this.form["precoFim"].patchValue("");
    this.form["anoInicio"].patchValue("");
    this.form["anoFim"].patchValue("");
    this.form["kmInicio"].patchValue("");
    this.form["kmFim"].patchValue("");
    this.form["todasMarcas"].patchValue(false);
    this.form["todasPlacas"].patchValue(false);
    this.form["todasCores"].patchValue(false);
    this.form["todosImplementos"].patchValue(false);

    this.limparMarcasFormArray();

    this.limparPlacasFormArray();

    this.limparCoresFormArray();

    this.limparImplementosFormArray();

    this.preencherFiltrosChebox();

    this.clearModelos();

    this.limparMarcasFormArray();

    this.clearMarcas();

    this.clearSearchCityState();

    this.filtrosLimpos = true;
  }

  public limparMarcasFormArray(): void {
    while (this.marcasFormArray.length !== 0) {
      this.marcasFormArray.removeAt(0);
    }
  }

  public limparImplementosFormArray(): void {
    while (this.implementosFormArray.length !== 0) {
      this.implementosFormArray.removeAt(0);
    }
  }

  public limparPlacasFormArray(): void {
    while (this.placasFormArray.length !== 0) {
      this.placasFormArray.removeAt(0);
    }
  }

  public limparCoresFormArray(): void {
    while (this.coresFormArray.length !== 0) {
      this.coresFormArray.removeAt(0);
    }
  }

  public irParaEstoqueCompleto(): void {
    this.parametros = {
      modeloMarca: [],
      modelo: "",
      categoria: "",
      ordem: "ano",
      marcas: "",
      eixo: "",
      filiais: [],
      placa: "",
      cor: "",
      anoInicio: "",
      anoFim: "",
      kmInicio: "",
      kmFim: "",
      precoInicio: "",
      precoFim: "",
      implementos: [],
      destaque: true,
      pagina: "1",
    };
    this.router.navigate(["seminovos/estoque-completo"]);
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        this.veiculos = ativosPDV.lista.results;
        this.paginacao = {
          paginaAtual: 1,
          itensTotais: ativosPDV.lista.pagination.total,
        };
      });
  }

  public ordenarPorMarcasDestaque(marcas: string[] = []): string[] {
    const marcasEmOrdemEspecifica: string[] = [
      "volkswagen",
      "vw - volkswagen",
      "scania",
      "volvo",
      "mercedes",
      "mercedes-benz",
      "iveco",
      "truckvan",
    ];

    const marcasFiltradas: string[] = marcas.filter((marca) =>
      marcasEmOrdemEspecifica.includes(marca)
    );

    const concatMarcas = marcasFiltradas.concat(marcas);

    const removerMarcasDuplicadas = [...new Set(concatMarcas)];

    const marcasReordenadas: string[] = removerMarcasDuplicadas.sort((a, b) => {
      const ordemEspecifica: Map<string, number> = new Map([
        ["volkswagen", 0],
        ["vw - volkswagen", 1],
        ["scania", 2],
        ["volvo", 3],
        ["mercedes", 4],
        ["mercedes-benz", 5],
        ["iveco", 6],
        ["truckvan", 7],
      ]);
      return ordemEspecifica.get(a)! - ordemEspecifica.get(b)!;
    });

    return marcasReordenadas;
  }

  handleSearchFiliais() {
    const localizacao = this.filtroVeiculosForm.get("localizacao")?.value ?? "";

    if (this.isMinimumSearchLength()) {
      this.isSearchCidadeEstadoOpen = true;
      this.pdvService
        .buscarVeiculoPorCidadeEstado({
          termo: localizacao,
        })
        .subscribe((data) => {
          this.searchCidadeEstado = data;
        });
    } else {
      this.isSearchCidadeEstadoOpen = false;
      this.searchCidadeEstado = [
        { cidades: [], estados: [] },
      ] as unknown as SearchByCityStateResponse;
    }
  }

  closeMarcasSidebar() {
    this.isMarcasOpen = false;
  }

  isMinimumSearchLength() {
    const localizacao = this.filtroVeiculosForm.get("localizacao")?.value ?? "";

    return localizacao.length >= 3;
  }

  isCityStateSearchEmpty() {
    return (
      !this.searchCidadeEstado.cidades?.length &&
      !this.searchCidadeEstado.estados?.length
    );
  }

  filtraPorCidade(cidade: string) {
    this.currentCidadeEstado = cidade;
    this.isSearchCidadeEstadoOpen = false;

    this.buscarLoja(
      this.searchCidadeEstado.cidades.find(
        (cidadeArr) => cidadeArr.cidade === cidade
      )?.filiais ?? []
    );

    this.filtroVeiculosForm.get("localizacao")?.patchValue(cidade);
  }

  filtraPorEstado(estado: string) {
    this.currentCidadeEstado = estado;
    this.isSearchCidadeEstadoOpen = false;

    this.buscarLoja(
      this.searchCidadeEstado.estados.find(
        (estadoArr) => estadoArr.estado === estado
      )?.filiais ?? []
    );

    this.filtroVeiculosForm.get("localizacao")?.patchValue(estado);
  }

  filtrarSuperPreco() {
    this.parametros.pagina = "1";
    this.parametros.destaque = this.filtroVeiculosForm.value["superPreco"];
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        this.veiculos = ativosPDV.lista.results;
        this.paginacao = {
          paginaAtual: 1,
          itensTotais: ativosPDV.lista.pagination.total,
        };
      });
  }

  clearSearchCityState() {
    this.currentCidadeEstado = "";
    this.filtroVeiculosForm.controls["localizacao"].reset();
    this.isSearchCidadeEstadoOpen = false;
    this.buscarLoja([]);
  }

  public buscarModelo(modelo: string): void {
    this.closeModelo();
    if (this.debounceBuscarModelo) clearTimeout(this.debounceBuscarModelo);

    this.debounceBuscarModelo = setTimeout(() => {
      this.parametros.modelo = modelo ?? "";

      this.parametros.pagina = "1";
      this.pdvSubscription = this.pdvService
        .buscarVeiculos(this.parametros)
        .subscribe((ativosPDV) => {
          this.veiculos = ativosPDV.lista.results;
          this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          };
        });
    }, 500);
  }

  public buscarModeloMarca(): void {
    this.closeModelo();
    if (this.debounceBuscarModelo) clearTimeout(this.debounceBuscarModelo);

    this.debounceBuscarModelo = setTimeout(() => {
      this.parametros.modeloMarca = this.modelosBusca;
      this.parametros.pagina = "1";
      this.pdvSubscription = this.pdvService
        .buscarVeiculos(this.parametros)
        .subscribe((ativosPDV) => {
          this.veiculos = ativosPDV.lista.results;
          this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          };
        });
    }, 500);
  }

  public ordenarPor(ordemSelecionada: AbstractControl<any, any>): void {
    this.parametros.ordem = ordemSelecionada.value;
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        this.veiculos = ativosPDV.lista.results;
      });
  }

  public preencherFiltrosChebox(): void {
    this.filtroMarcasSubscription = this.filtroVeiculos$.subscribe(
      (filtros) => {
        filtros.marcas?.forEach(() =>
          this.marcasFormArray.push(new FormControl(false))
        );

        filtros.implementos?.forEach(() =>
          this.implementosFormArray.push(new FormControl(false))
        );

        filtros?.placas
          .filter((placa) => placa != "indefinido")
          .forEach(() => this.placasFormArray.push(new FormControl(false)));

        filtros?.cores
          .filter((cor) => cor != "indefinido")
          .forEach(() => this.coresFormArray.push(new FormControl(false)));
      }
    );

    this.form["todasMarcas"].patchValue(true);
    this.form["todasPlacas"].patchValue(true);
    this.form["todasCores"].patchValue(true);
    this.form["todosImplementos"].patchValue(true);

    this.parametros.marcas = "";
    this.parametros.placa = "";
    this.parametros.cor = "";
    this.parametros.implementos = [];
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public buscarMarcas(marca: string): void {
    this.closeModelo();
    this.closeMarcasSidebar();

    let marcas = "";
    if (this.filtrosLimpos) this.marcasBusca = [];
    const arrayMarcas = this.marcasBusca.length;
    const isMarcaSelected = this.marcasBusca.find(
      (marcaBusca) => marcaBusca === marca
    );

    if (!isMarcaSelected) {
      this.marcasBusca.push(marca);
      marcas = this.marcasBusca.toString();
      this.form["todasMarcas"].patchValue(false);
    }

    if (isMarcaSelected) {
      let index = this.marcasBusca.findIndex((index) => index == marca);
      this.marcasBusca.splice(index, 1);
      marcas = this.marcasBusca.toString();
      this.form["todasMarcas"].patchValue(false);
      this.removeMarca(marca);
    }

    if (
      this.marcasBusca.length == arrayMarcas ||
      this.marcasBusca.length === 0
    ) {
      marcas = "";
      this.form["todasMarcas"].patchValue(true);
    }
    this.parametros.marcas = marcas;
    this.parametros.pagina = "1";
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        this.veiculos = ativosPDV.lista.results;
        this.paginacao = {
          paginaAtual: 1,
          itensTotais: ativosPDV.lista.pagination.total,
        };
      });

    if (!isMarcaSelected)
      this.pdvService
        .buscarModelosPorMarcaECategoria({
          marca,
          categoria: this.parametros.categoria ?? "",
        })
        .subscribe((data) => {
          this.modelosPorMarca[marca] = data;
          this.filteredModelosPorMarca[marca] = data;
        });
    this.filtrosLimpos = false;
  }

  public buscarLoja(lojasSelecionadas: string[] | null): void {
    this.parametros.filiais = lojasSelecionadas?.length
      ? lojasSelecionadas.filter(Boolean)
      : [];
    this.parametros.pagina = "1";
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        this.veiculos = ativosPDV.lista.results;
        this.paginacao = {
          paginaAtual: 1,
          itensTotais: ativosPDV.lista.pagination.total,
        };
      });
  }

  public buscarEixo(eixoSelecionado: string): void {
    this.parametros.eixo = eixoSelecionado === "--" ? "" : eixoSelecionado;
    this.parametros.pagina = "1";
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public filtrarPreco(
    precoInicial: string | number = "",
    precoFinal: string | number = ""
  ): void {
    this.temPreco = true;
    this.form["precoInicio"].disable();
    this.form["precoFim"].disable();

    let precoInicialLimpo = precoInicial ?? "";
    let precoFinalLimpo = precoFinal ?? "";

    if (typeof precoInicial == "string") {
      precoInicialLimpo = precoInicial.replace(/\D/g, "");
    }

    if (typeof precoFinal == "string") {
      precoFinalLimpo = precoFinal.replace(/\D/g, "");
    }

    this.parametros.precoInicio = precoInicialLimpo.toString();
    this.parametros.precoFim = precoFinalLimpo.toString();
    this.parametros.pagina = "1";
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public buscarPlacas(status: AbstractControl<any, any>, placa: string): void {
    let placas = "";
    if (this.filtrosLimpos) this.placasBusca = [""];
    const arrayPlacas = this.placasBusca.length;
    if (!status.value) {
      this.placasBusca.push(placa);
      placas = this.placasBusca.toString();
      this.form["todasPlacas"].patchValue(false);
    }
    if (!!status.value) {
      let index = this.placasBusca.findIndex((index) => index == placa);
      this.placasBusca.splice(index, 1);
      placas = this.placasBusca.toString();
      this.form["todasPlacas"].patchValue(false);
    }

    if (
      this.placasBusca.length == arrayPlacas ||
      this.placasBusca.length === 0
    ) {
      placas = "";
      this.form["todasPlacas"].patchValue(true);
    }
    this.parametros.pagina = "1";
    this.parametros.placa = placas;
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
    this.filtrosLimpos = false;
  }

  public buscarCores(status: AbstractControl<any, any>, cor: string): void {
    let cores = "";
    if (this.filtrosLimpos) this.coresBusca = [""];
    const arrayMarcas = this.coresBusca.length;
    if (!status.value) {
      this.coresBusca.push(cor);
      cores = this.coresBusca.toString();
      this.form["todasCores"].patchValue(false);
    }
    if (!!status.value) {
      let index = this.coresBusca.findIndex((index) => index == cor);
      this.coresBusca.splice(index, 1);
      cores = this.coresBusca.toString();
      this.form["todasCores"].patchValue(false);
    }

    if (this.coresBusca.length == arrayMarcas || this.coresBusca.length === 0) {
      cores = "";
      this.form["todasCores"].patchValue(true);
    }
    this.parametros.pagina = "1";
    this.parametros.cor = cores;
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
    this.filtrosLimpos = false;
  }

  public buscarImplementos(
    status: AbstractControl<any, any>,
    implemento: string
  ): void {
    if (this.filtrosLimpos) this.implementosBusca = [""];
    const arrayImplementosSize = this.implementosBusca.length;

    if (!status.value) {
      this.implementosBusca.push(implemento);
      this.form["todosImplementos"].patchValue(false);
    }

    if (!!status.value) {
      let index = this.implementosBusca.findIndex(
        (index) => index == implemento
      );
      this.implementosBusca.splice(index, 1);
      this.form["todosImplementos"].patchValue(false);
    }

    if (
      this.implementosBusca.length == arrayImplementosSize ||
      this.implementosBusca.length === 0
    ) {
      this.form["todosImplementos"].patchValue(true);
    }

    this.parametros.pagina = "1";
    this.parametros.implementos = this.implementosBusca;
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
    this.filtrosLimpos = false;
  }

  public limparFiltroPreco(): void {
    this.temPreco = false;
    this.form["precoInicio"].enable();
    this.form["precoFim"].enable();
    this.form["precoInicio"].patchValue("");
    this.form["precoFim"].patchValue("");
    this.parametros.precoInicio = "";
    this.parametros.precoFim = "";
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public filtrarAno(anoInicial: string, anoFinal: string): void {
    this.temAno = true;
    this.form["anoInicio"].disable();
    this.form["anoFim"].disable();
    this.parametros.pagina = "1";

    this.parametros.anoInicio = anoInicial ?? "";
    this.parametros.anoFim = anoFinal ?? "";
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public limparFiltroAno(): void {
    this.temAno = false;
    this.form["anoInicio"].enable();
    this.form["anoFim"].enable();
    this.form["anoInicio"].patchValue("");
    this.form["anoFim"].patchValue("");
    this.parametros.anoInicio = "";
    this.parametros.anoFim = "";
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public filtrarKm(kmInicial: string, kmFinal: string): void {
    this.temKm = true;
    this.form["kmInicio"].disable();
    this.form["kmFim"].disable();
    let kmInicialLimpo = kmInicial ?? "";
    let kmFinalLimpo = kmFinal ?? "";
    this.parametros.pagina = "1";
    if (typeof kmInicial == "string") {
      kmInicialLimpo = kmInicial.replace(/\D/g, "");
    }

    if (typeof kmFinal == "string") {
      kmFinalLimpo = kmFinal.replace(/\D/g, "");
    }

    this.parametros.kmInicio = kmInicialLimpo;
    this.parametros.kmFim = kmFinalLimpo;
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public limparFiltroKm(): void {
    this.temKm = false;
    this.form["kmInicio"].enable();
    this.form["kmFim"].enable();
    this.form["kmInicio"].patchValue("");
    this.form["kmFim"].patchValue("");
    this.parametros.kmInicio = "";
    this.parametros.kmFim = "";
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: 1,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  public mascaraPrecoInicio(): void {
    const mascaraPreco = InputMasks.priceMask(
      this.form["precoInicio"].value
    ).toString();
    this.form["precoInicio"].setValue(mascaraPreco);
  }

  public mascaraPrecoFim(): void {
    const mascaraPreco = InputMasks.priceMask(
      this.form["precoFim"].value
    ).toString();
    this.form["precoFim"].setValue(mascaraPreco);
  }

  public mascaraKmInicio(): void {
    const mascaraKmInicio = InputMasks.kmMask(
      this.form["kmInicio"].value
    ).toString();

    this.form["kmInicio"].setValue(mascaraKmInicio);
  }

  public mascaraKmFim(): void {
    const mascaraKmFim = InputMasks.kmMask(this.form["kmFim"].value).toString();
    this.form["kmFim"].setValue(mascaraKmFim);
  }

  public pageChanged(pagina: number): void {
    this.parametros.pagina = pagina.toString();
    this.pdvSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        (this.veiculos = ativosPDV.lista.results),
          (this.paginacao = {
            paginaAtual: pagina,
            itensTotais: ativosPDV.lista.pagination.total,
          });
      });
  }

  searchModelosMarca(e: Event, marca: string) {
    const value = (e.target as HTMLInputElement).value;
    if (!value) return (this.filteredModelosPorMarca = this.modelosPorMarca);

    return (this.filteredModelosPorMarca[marca] = this.modelosPorMarca[
      marca
    ].filter((modelo) => modelo.includes(value)));
  }

  searchImplementos(e: Event) {
    const value = (e.target as HTMLInputElement).value;
    if (!value) {
      this.filteredImplementosControls = this.implementosFormArray.controls;
      this.filteredImplementos = this.implementos;
      return;
    }

    this.filteredImplementosControls =
      this.implementosFormArray.controls.filter((_, index) =>
        this.implementos[index].includes(value)
      );

    this.filteredImplementos = this.implementos.filter((implemento) =>
      implemento.includes(value)
    );

    return;
  }

  clearModelos(marca?: string) {
    this.currentMarca = "";
    this.isModelosOpen = false;
    if (!marca) {
      this.filteredModelosPorMarca = {};
      this.modelosPorMarca = {};
    } else {
      this.filteredModelosPorMarca[marca] = [];
      this.modelosPorMarca[marca] = [];
    }
  }

  getSelectedMarcas() {
    return this.marcasBusca;
  }

  openModelo(marca: string) {
    this.currentMarca = marca;
    this.isModelosOpen = true;
  }

  closeModelo() {
    this.currentMarca = "";
    this.isModelosOpen = false;
  }

  closeImplementosSidebar() {
    this.isImplementosOpen = false;
  }

  removeMarca(marca: string) {
    if (marca && this.filteredModelosPorMarca[marca])
      delete this.filteredModelosPorMarca[marca];

    if (marca && this.modelosPorMarca[marca])
      delete this.modelosPorMarca[marca];

    this.removeModelosDaMarca(marca);
  }

  clearMarcas() {
    this.marcasBusca = [];
    this.filteredModelosPorMarca = {};
    this.marcaSelecionada = "";
    this.modelosPorMarca = {};
    this.modelosBusca = [];

    this.clearModelos();
  }

  removeModelo({ modelo, marca }: { modelo: string; marca: string }) {
    const index = this.modelosBusca.findIndex(
      (modeloArr) => modeloArr.marca === marca && modeloArr.modelo === modelo
    );
    if (index !== -1) {
      this.modelosBusca.splice(index, 1);
    }

    this.buscarModeloMarca();
  }

  removeModelosDaMarca(marca: string) {
    this.modelosBusca = [...this.modelosBusca].filter(
      (modeloArr) => modeloArr.marca !== marca
    );
    this.buscarModeloMarca();
  }

  getModeloDaMarca(marca: string) {
    const result = this.modelosBusca.find(
      (modeloArr) => modeloArr.marca === marca
    );

    if (result) return result.modelo;
    return "";
  }

  addModelo({ modelo, marca }: { modelo: string; marca: string }) {
    this.modelosBusca.push({ modelo, marca });

    this.buscarModeloMarca();
  }

  isModeloSelected(modelo: string) {
    return this.modelosBusca
      .map((modeloArr) => modeloArr.modelo)
      .includes(modelo);
  }

  getImplementosPreview() {
    return this.implementosFormArray.controls.slice(0, 5);
  }

  getMarcaImage(marca: string) {
    switch (marca) {
      case "VOLKSWAGEN":
        return "assets/logos/vw_logo.jpg";
      case "VW":
        return "assets/logos/vw_logo.jpg";
      case "SCANIA":
        return "assets/logos/scania_logo.svg";
      case "MERCEDES":
        return "assets/logos/mercedes_logo.png";
      case "M.BENZ":
        return "assets/logos/mercedes_logo.png";
      case "MERCEDES-BENZ":
        return "assets/logos/mercedes_logo.png";
      case "VOLVO":
        return "assets/logos/volvo_logo.png";
      case "IVECO":
        return "assets/logos/iveco_logo.svg";
      case "FORD":
        return "assets/logos/ford_logo.jpg";
      case "HYUNDAI":
        return "assets/logos/hyundai_logo.jpg";
      case "KIA":
        return "assets/logos/kia_logo.avif";
      case "MAN":
        return "assets/logos/man_logo.png";
      case "FACCHINI":
        return "assets/logos/facchini_logo.jpg";
      case "LIBRELATO":
        return "assets/logos/librelato_logo.png";
      case "RANDON":
        return "assets/logos/randon_logo.png";
      case "SANTA IZABEL":
        return "assets/logos/santa_izabel_logo.png";
      case "CASE":
        return "assets/logos/case_logo.png";
      case "CATERPILLAR":
        return "assets/logos/caterpillar_logo.jpg";
      case "JACTO":
        return "assets/logos/jacto_logo.png";
      case "JOHN DEERE":
        return "assets/logos/john_deere_logo.png";
      case "FIAT":
        return "assets/logos/fiat_logo.jpg";
      case "HONDA":
        return "assets/logos/honda_logo.webp";
      case "KOMATSU":
        return "assets/logos/komatsu_logo.png";
      case "RENAULT":
        return "assets/logos/renault_logo.jpg";
      case "TENNANT":
        return "assets/logos/tennant_logo.png";
      default:
        return "assets/logos/no_logo.avif";
    }
  }
}
