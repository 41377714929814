import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { InputMasks } from "src/app/helpers/mask/InputMask";
import { MenuService } from "src/app/services/menu.service";
import { SempreNovoService } from "src/app/services/sempre-novo.service";
import { Filial } from "src/models/Filial";
import { FiltrosPDV } from "src/models/FiltrosPDV";
import { SempreNovoPDVParams } from "src/models/PDVParams";
import { SempreNovoModelo } from "src/models/SempreNovoModelo";

@Component({
  selector: "app-block-filtro-sempre-novo",
  templateUrl: "./block-filtro-sempre-novo.component.html",
  styleUrls: ["./block-filtro-sempre-novo.component.scss"],
})
export class BlockFiltroSempreNovoComponent implements OnInit, OnDestroy {
  public readonly filtroVeiculos$: Observable<FiltrosPDV> =
    this.sempreNovoService.filtroVeiculos$;
  public checkboxSubscription!: Subscription;
  public filtroMarcasSubscription!: Subscription;
  public modelosSubscription!: Subscription;
  public isMobileLayout = false;
  public modelos: SempreNovoModelo[] = [];

  public paginacao = { paginaAtual: 1, itensTotais: 12 };

  public parametros: SempreNovoPDVParams = {
    modelo: "",
    categoria: "",
    ordem: "ano",
    marcas: "",
    eixo: "",
    filial: "",
    placa: "",
    cor: "",
    anoInicio: "",
    anoFim: "",
    kmInicio: "",
    kmFim: "",
    precoInicio: "",
    precoFim: "",
    pagina: "1",
  };
  public debounceBuscarModelo: any = null;
  public temPreco = false;
  public temAno = false;
  public temKm = false;
  public filtrosLimpos = false;

  public marcasBusca: string[] = [];

  public placasBusca: string[] = [];

  public coresBusca: string[] = [];

  public listaOrdem = [
    { id: "ano", label: "Mais Novo" },
    { id: "menorvalor", label: "Menor Valor" },
    { id: "maiorvalor", label: "Maior Valor" },
    { id: "km", label: "Menor Quilometragem" },
  ];

  public categoriaSelecionada = "";

  public marcaSelecionada = "";

  public placaSelecionada = "";

  public corSelecionada = "";

  public lojaSelecionada!: Filial[];

  public marcasNaOrdem: string[] = [];

  public placasNaOrdem: string[] = [];

  public coresNaOrdem: string[] = [];

  public filtroVeiculosForm: FormGroup = new FormGroup({});

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public menuService: MenuService,
    public sempreNovoService: SempreNovoService
  ) {
    this.filtroVeiculosForm = this.fb.group({
      modelo: new FormControl(null),
      todasMarcas: new FormControl({ value: true, disabled: false }),
      marcas: new FormArray([]),
      filial: new FormControl(null),
      todasPlacas: new FormControl({ value: true, disabled: false }),
      placas: new FormArray([]),
      todasCores: new FormControl({ value: true, disabled: false }),
      cores: new FormArray([]),
      eixo: new FormControl(null),
      ordem: new FormControl({ id: "ano", label: "Mais Novo" }),
      precoInicio: new FormControl(null),
      precoFim: new FormControl(null),
      anoInicio: new FormControl(null, Validators.minLength(4)),
      anoFim: new FormControl(null, Validators.minLength(4)),
      kmInicio: new FormControl(null),
      kmFim: new FormControl(null),
    });
  }

  public ngOnInit(): void {
    if (this.route.snapshot.queryParams["marcas"]) {
      this.parametros.marcas = this.route.snapshot.queryParams["marcas"];
    }

    if (Object.keys(this.route.snapshot.queryParams)[0] == "modelo") {
      this.parametros.modelo = this.route.snapshot.queryParams["modelo"];
    }

    this.checkboxSubscription = this.filtroVeiculos$.subscribe((filtros) => {
      this.limparMarcasFormArray();
      this.limparPlacasFormArray();
      this.limparCoresFormArray();
      this.marcasNaOrdem = this.ordenarPorMarcasDestaque(filtros.marcas);
      this.placasNaOrdem = filtros.placas
        .filter((placa) => placa != "indefinido")
        .sort();
      this.coresNaOrdem = filtros.cores
        .filter((cor) => cor != "indefinido")
        .sort();
      filtros.marcas.forEach(() =>
        this.marcasFormArray.push(new FormControl(false))
      );
      filtros.placas
        .filter((placa) => placa != "indefinido")
        .forEach(() => this.placasFormArray.push(new FormControl(false)));
      filtros.cores
        .filter((cor) => cor != "indefinido")
        .forEach(() => this.coresFormArray.push(new FormControl(false)));
    });

    this.updateModelosSubscription();

    this.sempreNovoService.listarFiltros();

    this.form["ordem"].patchValue("ano");
  }

  private updateModelosSubscription() {
    this.modelosSubscription = this.sempreNovoService
      .buscarModelos(this.parametros)
      .subscribe((response) => {
        this.modelos = response.lista.results;
        this.paginacao = {
          itensTotais: response.lista.pagination.total,
          paginaAtual: response.lista.pagination.page,
        };
      });
  }

  public ngOnDestroy(): void {
    this.modelosSubscription?.unsubscribe();
    this.checkboxSubscription?.unsubscribe();
    this.filtroMarcasSubscription?.unsubscribe();
  }

  public limparFiltros(): void {
    this.temPreco = false;
    this.temAno = false;
    this.temKm = false;
    this.form["kmInicio"].enable();
    this.form["kmFim"].enable();
    this.form["anoInicio"].enable();
    this.form["anoFim"].enable();
    this.form["precoInicio"].enable();
    this.form["precoFim"].enable();

    this.definirValoresPadrao();

    this.parametros = {
      modelo: "",
      categoria: this.categoriaSelecionada,
      ordem: "ano",
      marcas: this.marcaSelecionada,
      eixo: "",
      filial: "",
      placa: this.placaSelecionada,
      cor: this.corSelecionada,
      anoInicio: "",
      anoFim: "",
      kmInicio: "",
      kmFim: "",
      precoInicio: "",
      precoFim: "",
      pagina: "1",
    };

    this.updateModelosSubscription();
  }

  public toggleMenuFiltroMobile(): void {
    this.menuService.toggleMenuFiltroMobile();
  }

  public definirValoresPadrao(): void {
    this.form["ordem"].patchValue("ano");
    this.form["modelo"].patchValue("");
    this.form["filial"].patchValue(null);
    this.form["eixo"].patchValue("");
    this.form["precoInicio"].patchValue("");
    this.form["precoFim"].patchValue("");
    this.form["anoInicio"].patchValue("");
    this.form["anoFim"].patchValue("");
    this.form["kmInicio"].patchValue("");
    this.form["kmFim"].patchValue("");
    this.form["todasMarcas"].patchValue(false);
    this.form["todasPlacas"].patchValue(false);
    this.form["todasCores"].patchValue(false);

    this.limparMarcasFormArray();

    this.limparPlacasFormArray();

    this.limparCoresFormArray();

    this.preencherFiltrosChebox();

    this.filtrosLimpos = true;
  }

  public limparMarcasFormArray(): void {
    while (this.marcasFormArray.length !== 0) {
      this.marcasFormArray.removeAt(0);
    }
  }

  public limparPlacasFormArray(): void {
    while (this.placasFormArray.length !== 0) {
      this.placasFormArray.removeAt(0);
    }
  }

  public limparCoresFormArray(): void {
    while (this.coresFormArray.length !== 0) {
      this.coresFormArray.removeAt(0);
    }
  }

  public irParaEstoqueCompleto(): void {
    this.parametros = {
      modelo: "",
      categoria: "",
      ordem: "ano",
      marcas: "",
      eixo: "",
      filial: "",
      placa: "",
      cor: "",
      anoInicio: "",
      anoFim: "",
      kmInicio: "",
      kmFim: "",
      precoInicio: "",
      precoFim: "",
      pagina: "1",
    };
    this.router.navigate(["seminovos/estoque-completo"]);
    this.updateModelosSubscription();
  }

  public ordenarPorMarcasDestaque(marcas: string[]): string[] {
    const marcasEmOrdemEspecifica: string[] = [
      "volkswagen",
      "vw - volkswagen",
      "scania",
      "volvo",
      "mercedes",
      "mercedes-benz",
      "iveco",
      "truckvan",
    ];

    const marcasFiltradas: string[] = marcas.filter((marca) =>
      marcasEmOrdemEspecifica.includes(marca)
    );

    const concatMarcas = marcasFiltradas.concat(marcas);

    const removerMarcasDuplicadas = [...new Set(concatMarcas)];

    const marcasReordenadas: string[] = removerMarcasDuplicadas.sort((a, b) => {
      const ordemEspecifica: Map<string, number> = new Map([
        ["volkswagen", 0],
        ["vw - volkswagen", 1],
        ["scania", 2],
        ["volvo", 3],
        ["mercedes", 4],
        ["mercedes-benz", 5],
        ["iveco", 6],
        ["truckvan", 7],
      ]);
      return ordemEspecifica.get(a)! - ordemEspecifica.get(b)!;
    });

    return marcasReordenadas;
  }

  public buscarModelo(modelo: string): void {
    if (this.debounceBuscarModelo) clearTimeout(this.debounceBuscarModelo);

    this.debounceBuscarModelo = setTimeout(() => {
      this.parametros.modelo = modelo;
      this.updateModelosSubscription();
    }, 500);
  }

  public ordenarPor(ordemSelecionada: AbstractControl<any, any>): void {
    this.parametros.ordem = ordemSelecionada.value;
    this.updateModelosSubscription();
  }

  public preencherFiltrosChebox(): void {
    this.filtroMarcasSubscription = this.filtroVeiculos$.subscribe(
      (filtros) => {
        filtros.marcas.forEach(() =>
          this.marcasFormArray.push(new FormControl(false))
        );

        filtros.placas
          .filter((placa) => placa != "indefinido")
          .forEach(() => this.placasFormArray.push(new FormControl(false)));

        filtros.cores
          .filter((cor) => cor != "indefinido")
          .forEach(() => this.coresFormArray.push(new FormControl(false)));
      }
    );

    this.form["todasMarcas"].patchValue(true);
    this.form["todasPlacas"].patchValue(true);
    this.form["todasCores"].patchValue(true);

    this.parametros.marcas = "";
    this.parametros.placa = "";
    this.parametros.cor = "";
    this.updateModelosSubscription();
  }

  public buscarMarcas(status: AbstractControl<any, any>, marca: string): void {
    let marcas = "";
    if (this.filtrosLimpos) this.marcasBusca = [""];
    const arrayMarcas = this.marcasBusca.length;
    if (!status.value) {
      this.marcasBusca.push(marca);
      marcas = this.marcasBusca.toString();
      this.form["todasMarcas"].patchValue(false);
    }
    if (!!status.value) {
      let index = this.marcasBusca.findIndex((index) => index == marca);
      this.marcasBusca.splice(index, 1);
      marcas = this.marcasBusca.toString();
      this.form["todasMarcas"].patchValue(false);
    }

    if (
      this.marcasBusca.length == arrayMarcas ||
      this.marcasBusca.length === 0
    ) {
      marcas = "";
      this.form["todasMarcas"].patchValue(true);
    }
    this.parametros.marcas = marcas;
    this.updateModelosSubscription();
    this.filtrosLimpos = false;
  }

  public buscarLoja(lojaSelecionada: string | null): void {
    this.parametros.filial = lojaSelecionada ?? "";
    this.parametros.pagina = "1";
    this.updateModelosSubscription();
  }

  public buscarEixo(eixoSelecionado: string): void {
    this.parametros.eixo = eixoSelecionado;
    this.updateModelosSubscription();
  }

  public filtrarPreco(
    precoInicial: string | number,
    precoFinal: string | number
  ): void {
    this.temPreco = true;
    this.form["precoInicio"].disable();
    this.form["precoFim"].disable();

    let precoInicialLimpo = precoInicial;
    let precoFinalLimpo = precoFinal;

    if (typeof precoInicial == "string") {
      precoInicialLimpo = precoInicial.replace(/\D/g, "");
    }

    if (typeof precoFinal == "string") {
      precoFinalLimpo = precoFinal.replace(/\D/g, "");
    }

    if (precoInicial) {
      this.parametros.precoInicio = precoInicialLimpo.toString();
      this.updateModelosSubscription();
    }

    if (precoFinal) {
      this.parametros.precoFim = precoFinalLimpo.toString();
      this.updateModelosSubscription();
    }
  }

  public buscarPlacas(status: AbstractControl<any, any>, placa: string): void {
    let placas = "";
    if (this.filtrosLimpos) this.placasBusca = [""];
    const arrayPlacas = this.placasBusca.length;
    if (!status.value) {
      this.placasBusca.push(placa);
      placas = this.placasBusca.toString();
      this.form["todasPlacas"].patchValue(false);
    }
    if (!!status.value) {
      let index = this.placasBusca.findIndex((index) => index == placa);
      this.placasBusca.splice(index, 1);
      placas = this.placasBusca.toString();
      this.form["todasPlacas"].patchValue(false);
    }

    if (
      this.placasBusca.length == arrayPlacas ||
      this.placasBusca.length === 0
    ) {
      placas = "";
      this.form["todasPlacas"].patchValue(true);
    }

    this.parametros.placa = placas;
    this.updateModelosSubscription();
    this.filtrosLimpos = false;
  }

  public buscarCores(status: AbstractControl<any, any>, cor: string): void {
    let cores = "";
    if (this.filtrosLimpos) this.coresBusca = [""];
    const arrayMarcas = this.coresBusca.length;
    if (!status.value) {
      this.coresBusca.push(cor);
      cores = this.coresBusca.toString();
      this.form["todasCores"].patchValue(false);
    }
    if (!!status.value) {
      let index = this.coresBusca.findIndex((index) => index == cor);
      this.coresBusca.splice(index, 1);
      cores = this.coresBusca.toString();
      this.form["todasCores"].patchValue(false);
    }

    if (this.coresBusca.length == arrayMarcas || this.coresBusca.length === 0) {
      cores = "";
      this.form["todasCores"].patchValue(true);
    }

    this.parametros.cor = cores;
    this.updateModelosSubscription();
    this.filtrosLimpos = false;
  }

  public limparFiltroPreco(): void {
    this.temPreco = false;
    this.form["precoInicio"].enable();
    this.form["precoFim"].enable();
    this.form["precoInicio"].patchValue("");
    this.form["precoFim"].patchValue("");
    this.parametros.precoInicio = "";
    this.parametros.precoFim = "";
    this.updateModelosSubscription();
  }

  public filtrarAno(anoInicial: string, anoFinal: string): void {
    this.temAno = true;
    this.form["anoInicio"].disable();
    this.form["anoFim"].disable();
    if (anoInicial) {
      this.parametros.anoInicio = anoInicial;
      this.updateModelosSubscription();
    }
    if (anoFinal) {
      this.parametros.anoFim = anoFinal;
      this.updateModelosSubscription();
    }
  }

  public limparFiltroAno(): void {
    this.temAno = false;
    this.form["anoInicio"].enable();
    this.form["anoFim"].enable();
    this.form["anoInicio"].patchValue("");
    this.form["anoFim"].patchValue("");
    this.parametros.anoInicio = "";
    this.parametros.anoFim = "";
    this.updateModelosSubscription();
  }

  public filtrarKm(kmInicial: string, kmFinal: string): void {
    this.temKm = true;
    this.form["kmInicio"].disable();
    this.form["kmFim"].disable();
    let kmInicialLimpo = kmInicial;
    let kmFinalLimpo = kmFinal;

    if (typeof kmInicial == "string") {
      kmInicialLimpo = kmInicial.replace(/\D/g, "");
    }

    if (typeof kmFinal == "string") {
      kmFinalLimpo = kmFinal.replace(/\D/g, "");
    }

    if (kmInicial) {
      this.parametros.kmInicio = kmInicialLimpo;
      this.updateModelosSubscription();
    }

    if (kmFinal) {
      this.parametros.kmFim = kmFinalLimpo;
      this.updateModelosSubscription();
    }
  }

  public limparFiltroKm(): void {
    this.temKm = false;
    this.form["kmInicio"].enable();
    this.form["kmFim"].enable();
    this.form["kmInicio"].patchValue("");
    this.form["kmFim"].patchValue("");
    this.parametros.kmInicio = "";
    this.parametros.kmFim = "";
    this.updateModelosSubscription();
  }

  public get form(): {
    [key: string]: AbstractControl<any, any>;
  } {
    return this.filtroVeiculosForm.controls;
  }

  public get marcasFormArray(): FormArray<any> {
    return this.filtroVeiculosForm.controls["marcas"] as FormArray;
  }

  public get placasFormArray(): FormArray<any> {
    return this.filtroVeiculosForm.controls["placas"] as FormArray;
  }

  public get coresFormArray(): FormArray<any> {
    return this.filtroVeiculosForm.controls["cores"] as FormArray;
  }

  public mascaraPrecoInicio(): void {
    const mascaraPreco = InputMasks.priceMask(
      this.form["precoInicio"].value
    ).toString();
    this.form["precoInicio"].setValue(mascaraPreco);
  }

  public mascaraPrecoFim(): void {
    const mascaraPreco = InputMasks.priceMask(
      this.form["precoFim"].value
    ).toString();
    this.form["precoFim"].setValue(mascaraPreco);
  }

  public mascaraKmInicio(): void {
    const mascaraKmInicio = InputMasks.kmMask(
      this.form["kmInicio"].value
    ).toString();

    this.form["kmInicio"].setValue(mascaraKmInicio);
  }

  public mascaraKmFim(): void {
    const mascaraKmFim = InputMasks.kmMask(this.form["kmFim"].value).toString();
    this.form["kmFim"].setValue(mascaraKmFim);
  }

  public pageChanged(pagina: number): void {
    this.parametros.pagina = pagina.toString();
    this.updateModelosSubscription();
  }

  private _higienizaPalavraEspaçoViraTraco(palavra: string): string {
    let text = palavra.toLowerCase();
    text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
    text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
    text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
    text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
    text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
    text = text.replace(new RegExp("[Ç]", "gi"), "c");
    text = text.replace(/\((.*?)\)/g, "-");
    text = text.replace(/\s+/g, "-");
    text = text.replace(/---/g, "");

    return text;
  }
}
