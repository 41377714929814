import { Component, Input, OnInit } from '@angular/core';
import sortByOrder from 'src/app/helpers/sort/sortByOrder';
import { IBlockImageText } from 'src/models/zeus';

@Component({
  selector: 'app-block-image-text',
  templateUrl: './block-image-text.component.html',
  styleUrls: ['./block-image-text.component.scss']
})
export class BlockImageTextComponent implements OnInit {

  @Input() block!: IBlockImageText;

  public ngOnInit(): void {
    this.sortAllImageTexsByOrder();
  }

  public sortAllImageTexsByOrder(){
    if(this.block.ordenacao == "decrescente"){
      this.block.imagetexts = this.block.imagetexts.sort().reverse();
    } if(this.block.ordenacao == "crescente"){
      this.block.imagetexts = this.block.imagetexts.sort();
    }
  }

}
