import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { pdvService } from "src/app/services/pdv.service";
import { PDVParams } from "src/models/PDVParams";
import { ProdutoPdv } from "src/models/ProdutoPdv";
import { IBlockOfferCard } from "src/models/zeus";

@Component({
  selector: "app-block-offer-card",
  templateUrl: "./block-offer-card.component.html",
  styleUrls: ["./block-offer-card.component.scss"],
})
export class BlockOfferCardComponent implements OnInit, OnChanges {
  public marginFundo = new BehaviorSubject<number>(240);
  public heightFundo: number = 0;
  public veiculos: ProdutoPdv[] = [];
  public subscription!: Subscription;
  public veiculosSubscription!: Subscription;
  @ViewChild("element", { static: true }) element?: ElementRef<HTMLDivElement>;
  @Input() block!: IBlockOfferCard;
  public parametros: PDVParams = {
    modelo: "",
    modeloMarca: [],
    categoria: "",
    ordem: "ano",
    marcas: "",
    eixo: "",
    filiais: [],
    placa: "",
    cor: "",
    anoInicio: "",
    anoFim: "",
    kmInicio: "",
    kmFim: "",
    precoInicio: "",
    precoFim: "",
    destaque: this.block?.blockIdentifier === "destaque" ? true : false,
    implementos: [],
    pagina: "1",
  };
  constructor(
    public elementRef: ElementRef<HTMLElement>,
    private pdvService: pdvService
  ) {}

  public ngOnInit(): void {
    this.subscription = this.marginFundo.subscribe((tamanho) => {
      this.calcularHeight(tamanho);
    });

    let categoria = this.block.veiculopdv;
    if (categoria) {
      this.block.offercards = [];
      if (categoria == "ABAIXO DA TABELA") categoria = "OPORTUNIDADES";
      this.parametros.categoria = this.tratarCategoria(categoria);
      this.getVehicles();
    }
  }

  getVehicles() {
    this.veiculosSubscription = this.pdvService
      .buscarVeiculos(this.parametros)
      .subscribe((ativosPDV) => {
        return (this.veiculos = ativosPDV.lista.results);
      });
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.veiculosSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["block"]) {
      this.parametros = {
        modelo: "",
        modeloMarca: [],
        categoria: "",
        ordem: "ano",
        marcas: "",
        eixo: "",
        filiais: [],
        placa: "",
        cor: "",
        anoInicio: "",
        anoFim: "",
        kmInicio: "",
        kmFim: "",
        precoInicio: "",
        precoFim: "",
        implementos: [],
        destaque: this.block?.blockIdentifier === "destaque" ? true : false,
        pagina: "1",
      };

      this.getVehicles();
    }
  }

  private tratarCategoria(categoria: string): string {
    if (categoria == "CAVALO MECÂNICO") return "cavalo-mecanico";
    if (categoria == "ÔNIBUS RODOVIÁRIO") return "onibus-rodoviario";
    if (categoria == "ÔNIBUS URBANO") return "onibus-urbano";
    if (categoria == "MAQ. LINHA VERDE")
      return "maquinas-e-equipamentos-agricolas";
    if (categoria == "CAMINHÃO") return "caminhoes";
    if (categoria == "SEMPRE NOVO") return "sempre-novo";
    if (categoria == "CARRETA") return "carreta";
    if (categoria == "OPORTUNIDADES") return "abaixo-da-tabela";
    if (categoria == "UTILITÁRIO") return "utilitario";
    if (categoria == "IMPLEMENTOS") return "implementos";
    if (categoria == "ABAIXO DA TABELA") return "abaixo-da-tabela";
    return "";
  }

  public calcularHeight(margin: number) {
    this.heightFundo =
      this.elementRef.nativeElement.parentElement!.offsetHeight -
      margin -
      (this.element?.nativeElement.offsetTop ?? 0);
  }

  public swiperConfigMobile: any = {
    slidesPerView: "auto",
    spaceBetween: 24,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1366: {
        perViewDesktop: 4,
      },
    },
  };
}
