<div class="container flex flex-wrap w-100 image-text pb64 pt64"
  [class.imageBeforeText]="!this.imageText.imageBeforeText" style="gap: 32px;">
  <div class="flex box-esquerdo">
    <img class="mauto w-100" [quality]="'60'" widthImg="600" [appStrapiimage]="this.imageText.image.data">
  </div>
  <div class="flex flex-column flex-justify-center box-direito" style="gap: 32px">
    <div class="texto flex flex-column flex-justify-center" [appEditorjsparsehtml]="this.imageText.content"></div>
    <div class="flex flex-wrap" style="gap: 16px;" *ngIf="this.imageText.links.length">
      <app-botao-tipo1 class="redondo" *ngFor="let link of this.imageText.links" [strapilink]="link" cor1="var(--white)"
        cor2="var(--primary)"></app-botao-tipo1>
    </div>
  </div>

</div>