import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProdutoPdvComMediaeFipe } from "src/models/ProdutoPdv";
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { DataLayerService } from "src/app/services/data-layer.service";
import { pdvService } from "src/app/services/pdv.service";
import { Observable, catchError, of } from "rxjs";
import { AgendamentoFilialService } from "src/app/services/agendamento-filial.service";
import { ModalService } from "src/app/services/modal.service";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

@Component({
  selector: "app-block-product-description",
  templateUrl: "./block-product-description.component.html",
  styleUrls: ["./block-product-description.component.scss"],
})
export class BlockProductDescriptionComponent {
  public veiculo: ProdutoPdvComMediaeFipe = {
    id: 127297,
    codigo: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    publishedAt: null,
    sitemap_exclude: false,
    descricao: "",
    marca: "",
    codigoFipe: "",
    categoria: "",
    modelo: "",
    eixo: "",
    ano: "",
    km: 0,
    inativo: false,
    valor: 0,
    tipo: "",
    tipoAquisicao: "",
    url: "",
    cor: "",
    implementos: "",
    placa: "",
    updateCode: "",
    placaFinal: "",
    anoModelo: 2021,
    anoFabricacao: 2021,
    codigoVeiculo: "",
    imagens: "",
    filial: {
      uf: "",
      cep: null,
      nome: "",
      bairro: null,
      cidade: "",
      endereco: "",
      telefone: "null",
      linkGoogleMaps: null,
      empresaEnum: 0,
    },
    filial_nome: "",
    createdBy: null,
    updatedBy: null,
    precoFipe: "",
    mediaVamos: 0,
  };

  public codigoVeiculo!: string;
  public imagens: string[] = [];
  public thumbsSwiper: any;
  agendamentoEmbed = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pdvService: pdvService,
    public dataLayerService: DataLayerService,
    private agendamentoService: AgendamentoFilialService,
    public modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.codigoVeiculo = this.route.snapshot.url[2].path;
    this.pdvService
      .buscarVeiculo(this.codigoVeiculo)
      .pipe(
        catchError((err: any) => {
          err;
          this.router.navigateByUrl("/seminovos");
          return of();
        })
      )
      .subscribe((veiculoPdv) => {
        this.veiculo = veiculoPdv;
        this.imagens = veiculoPdv.imagens.split(",");
        // this.getAgendamentoInfo();
      });
  }

  getAgendamentoInfo() {
    this.agendamentoService
      .getFilialCalendario(this.veiculo.filial.empresaEnum)
      .subscribe((data) => {
        if (data?.embed) this.agendamentoEmbed = data.embed;
      });
  }

  public swiperConfigMobile: any = {
    slidesPerView: "auto",
    spaceBetween: 24,
    breakpoints: {
      0: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 3,
      },
      1366: {
        perViewDesktop: 4,
      },
    },
  };

  public currencyValue(value: number | undefined) {
    if (value != undefined) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
    return value;
  }

  agendarVideoChamada() {}
}
