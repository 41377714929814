<a [appStrapilink]="strapilink"
  [style]="'--cor1: '+cor1+'; --cor2: '+cor2+'; --hvcor1: '+hvcor1+'; --hvcor2: '+hvcor2+'; background-color: var(--cor2);'"
  [title]="alt" [href]="href" class="upc s2 flex flex-items-center pa8 pl16 pr16 glass">

  <img class="mr8" *ngIf="strapiicon?.data || icon" style="background-color: var(--cor1); margin-left: -8px;"
    [appStrapiicon]="strapiicon?.data" [icon]="icon" [alt]="(strapilink?.label) ?? alt" width="20px" height="20px">

  <p style="color: var(--cor1)" class="fw500 ">{{ strapilink?.label ?? label }}</p>

</a>
