<ng-container *ngIf="page$ | async as page; else carregando">

  <!-- {{ page.url }} -->

  <!-- Tratamos as sections -->
  <section *ngFor="let section of sections; let j = index" style="overflow: hidden;">
    <!-- Tratamos os blocos -->
    <ng-container *ngFor="let bloco of section; let i = index">

      <ng-container [ngSwitch]="bloco['__component']">

        <app-block-carousel class="flex flex-column" [class.regra-especial]="i == 0 && j == 0"
          [primeiroBloco]="i == 0 && j == 0" *ngSwitchCase="'block.block-carousel'"
          [block]="bloco"></app-block-carousel>
        <app-block-carousel-form class="flex flex-column" [class.regra-especial]="i == 0 && j == 0"
          [primeiroBloco]="i == 0 && j == 0" *ngSwitchCase="'block.block-carousel-form'"
          [block]="bloco"></app-block-carousel-form>
        <app-block-content *ngSwitchCase="'block.block-content'" [block]="bloco"></app-block-content>
        <app-block-card #blockCard *ngSwitchCase="'block.block-card'" [block]="bloco"></app-block-card>
        <app-block-banner *ngSwitchCase="'block.block-banner'" [block]="bloco"></app-block-banner>
        <app-block-image-text *ngSwitchCase="'block.block-image-text'" [block]="bloco"></app-block-image-text>
        <app-block-badge *ngSwitchCase="'block.block-badge'" [block]="bloco"></app-block-badge>
        <app-block-medal *ngSwitchCase="'block.block-medal'" [block]="bloco"></app-block-medal>
        <app-block-plan-card *ngSwitchCase="'block.block-plan-card'" [block]="bloco"></app-block-plan-card>
        <app-block-testimonial *ngSwitchCase="'block.block-testimonial'" [block]="bloco"></app-block-testimonial>
        <app-block-youtube-video *ngSwitchCase="'block.block-youtube-video'" [block]="bloco"></app-block-youtube-video>
        <app-block-offer-card *ngSwitchCase="'block.block-offer-card'" [block]="bloco"></app-block-offer-card>
        <app-block-offer-sempre-novo *ngSwitchCase="'block.block-offer-sempre-novo'" [block]="bloco"></app-block-offer-sempre-novo>
        <app-block-faq *ngSwitchCase="'block.block-faq'" [block]="bloco"></app-block-faq>
        <app-block-shop-map *ngSwitchCase="'block.block-shop-map'" [block]="bloco"></app-block-shop-map>
        <app-block-news-letter *ngSwitchCase="'block.block-newsletter'" [block]="bloco"></app-block-news-letter>
        <app-block-glossary *ngSwitchCase="'block.block-glossary'" [block]="bloco"></app-block-glossary>
        <app-block-contact-form *ngSwitchCase="'block.block-contact-form'" [block]="bloco"></app-block-contact-form>
        <app-block-budget-form *ngSwitchCase="'block.block-budget-form'" [block]="bloco"></app-block-budget-form>
        <app-block-plan-table *ngSwitchCase="'block.block-plan-table'" [block]="bloco"></app-block-plan-table>
        <app-block-card-store *ngSwitchCase="'block.block-card-store'" [block]="bloco"></app-block-card-store>
        <app-block-shop-list *ngSwitchCase="'block.block-shop-list'" [block]="bloco"></app-block-shop-list>
        <app-block-blog-post-list-and-filter *ngSwitchCase="'block.block-blog-post-list-and-filter'" [block]="bloco">
        </app-block-blog-post-list-and-filter>
        <app-block-latest-blog-posts *ngSwitchCase="'block.block-latest-blog-posts'" [block]="bloco">
        </app-block-latest-blog-posts>
        <app-block-contact-us *ngSwitchCase="'block.block-contact-us'" [block]="bloco"></app-block-contact-us>
        <app-block-seminovos-search-bar *ngSwitchCase="'block.block-seminovos-search-bar'"
          [block]="bloco"></app-block-seminovos-search-bar>

        <!-- SEMPRE NOVO -->
        <app-sn-block-header *ngSwitchCase="'sempre-novo.sn-block-header'" [block]="bloco"></app-sn-block-header>
        <app-sn-block-subheader *ngSwitchCase="'sempre-novo.sn-sub-header'" [block]="bloco"></app-sn-block-subheader>
        <app-sn-block-legal *ngSwitchCase="'sempre-novo.sn-legal'" [block]="bloco"></app-sn-block-legal>

      </ng-container>

    </ng-container>
  </section>

  <!-- CONTROLE DAS MODAIS -->
  <section *ngFor="let bloco of modals">
    <ng-container [ngSwitch]="bloco['__component']">

      <ng-container *ngSwitchCase="'block.block-contact-form'">
        <app-modal [id]="bloco.identifier" #j>
          <p head class="s10 fw600 cor-primary">Formulário</p>
          <div body>
            <app-block-contact-form [block]="bloco" *ngIf="!j.hidden"
              (after-if)="this.dataLayerService.push({'event': 'iniciar_modal','nome_modal': j.id})"
              class="estilo-modal" style="display: block;"></app-block-contact-form>
          </div>
        </app-modal>
      </ng-container>

      <ng-container *ngSwitchCase="'block.block-budget-form'">
        <app-modal [id]="bloco.identifier" #j>
          <p head class="s10 fw600 cor-primary">Formulário</p>
          <div body>
            <app-block-budget-form [block]="bloco" *ngIf="!j.hidden"
              (after-if)="this.dataLayerService.push({'event': 'iniciar_modal','nome_modal': j.id})"
              class="estilo-modal" style="display: block;"></app-block-budget-form>
          </div>
        </app-modal>
      </ng-container>

    </ng-container>
  </section>

</ng-container>
<ng-template #carregando>
  <div class="fake">
    <div class="carregando-page">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>