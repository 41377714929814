import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import {
  GoogleMap,
  MapMarkerClusterer,
} from '@angular/google-maps';
import { StrapiRetorno } from 'src/models/strapi_retorno';
import { IBlockShopMap, IShop } from 'src/models/zeus';

type mark = {
  shop: IShop;
  position: google.maps.LatLngLiteral;
  title: string;
  options: google.maps.MarkerOptions;
  elementoNaLista?: HTMLElement;
};

@Component({
  selector: 'app-block-shop-map',
  templateUrl: './block-shop-map.component.html',
  styleUrls: ['./block-shop-map.component.scss'],
})
export class BlockShopMapComponent implements AfterViewInit{

  @Input() block!: IBlockShopMap;
  @ViewChild(GoogleMap, { static: false }) map?: GoogleMap;
  @ViewChild(MapMarkerClusterer, { static: false })

  public shops: IShop[] = [];
  center: google.maps.LatLngLiteral = {
    lat: -23.542082214220766,
    lng: -46.23145201622518,
  };

  public markers: mark[] = [];
  public filteredMarkers: mark[] = [];
  public mapClusterer?: MapMarkerClusterer;

  public options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 100,
    minZoom: 0,
  };

  public ngAfterViewInit(): void {
    (this.block.shops as StrapiRetorno<IShop>)?.data.forEach((shop) => {
      const s = { ...shop.attributes, id: shop.id?.toString() };
      this.markers.push({
        shop: s,
        position: { lat: Number(s.latitude), lng: Number(s.longitude) },
        title: s.name + '',
        options: { animation: google.maps.Animation.DROP},
      });
      this.shops = [s];
    });

    var bounds = new google.maps.LatLngBounds();
    this.markers.forEach((m) => {
      bounds.extend(m.position);
    });

    this.filteredMarkers = this.markers.sort(this.sortByShopName);
  }

  public sortByShopName(a:any, b:any){
    let _a: any;
    let _b: any;
    if (a.shop.name != undefined && b.shop.name != undefined) {
      _a = a.shop['name'];
      _b = b.shop['name'];

      if (_a < _b) {
        return -1;
      }

      if (_a > _b) {
        return 1;
      }
    }
    return 0;
  }

  public focarPontoNoMapa(mark: mark) {
    this.map?.googleMap?.setCenter(mark.position);
  }

  public mostrarNaLista(mark: mark) {
    mark.elementoNaLista?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }

  public bindarElementoNoMark(mark: mark, elemento: HTMLElement) {
    mark.elementoNaLista = elemento;
  }

  public obterTodosEstados() {
    const listaEstados: Array<string | undefined> = [];
    this.markers.forEach((s) => {
      if (listaEstados.indexOf(s.shop.state?.toUpperCase()) == -1) {
        listaEstados.push(s.shop.state?.toUpperCase());
      }
    });
    return listaEstados.sort();
  }

  public filtrarPorEstado(e: Event | null): void {
    const target = e?.target as HTMLInputElement;
    const query = target.value;
    if (query.length == 0) {
      this.filteredMarkers = this.markers;
    } else {
      this.filteredMarkers = this.markers
        .filter((m) => m.shop.state?.toUpperCase() == query)
        .sort(this.sortByShopName);
    }
  }
}


