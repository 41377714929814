import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { map, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Menus } from 'src/models/Menus';
import { StrapiRetorno } from 'src/models/strapi_retorno';
import { HttpStateService } from './http.state.service';
import { TradutorService } from './tradutor.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private httpStateService: HttpStateService,
    private tradutorService: TradutorService,
    @Inject(DOCUMENT) private document: Document,
  ) {

  }

  public menuHeaderMobileOpen = false;
  public menuFiltroMobileOpen = false;

  private url: string = environment.API_URL + environment.API_PATH_MENUS;

  public menuHeader$ = this.tradutorService.locale$.pipe(mergeMap(linguagem => {
    return this.httpStateService.get<StrapiRetorno<Menus>>(this.url + `?filters[slug][$eq]=${linguagem}-header&populate=*&nested`).pipe(map(menus => menus.data?.[0]?.attributes))
  }));
  public menuFooter$ = this.tradutorService.locale$.pipe(mergeMap(linguagem => {
    return this.httpStateService.get<StrapiRetorno<Menus>>(this.url + `?filters[slug][$eq]=${linguagem}-footer&populate=*&nested`).pipe(map(menus => menus.data?.[0]?.attributes))
  }));

  toggleMenuFiltroMobile() {
    this.menuFiltroMobileOpen = !this.menuFiltroMobileOpen;
    (this.document.body.style.overflow as any) = !!this.menuFiltroMobileOpen ? "hidden" : null;
  }
  toggleMenuHeaderMobile() {
    this.menuHeaderMobileOpen = !this.menuHeaderMobileOpen;
    (this.document.body.style.overflow as any) = !!this.menuHeaderMobileOpen ? "hidden" : null;
  }


}
