import { Component, Input } from '@angular/core';
import { ILink } from 'src/models/zeus';

@Component({
  selector: 'app-botao-tipo1',
  templateUrl: './botao-tipo1.component.html',
  styleUrls: ['./botao-tipo1.component.scss']
})
export class BotaoTipo1Component {

  constructor() { }

  @Input() strapilink?: ILink;
  @Input() strapiicon?: Arquivo;
  @Input() icon?: string;
  @Input() href?: string;
  @Input() alt?: string;
  @Input() label?: string;

  @Input() cor1?: string = 'var(--primary)';
  @Input() cor2?: string = 'var(--white)';
  @Input() hvcor1?: string = 'var(--white)';
  @Input() hvcor2?: string = 'var(--secondary)';

}
